import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'
import { Integrations } from "@sentry/tracing"
import App from 'layouts/App'
// import * as serviceWorker from 'serviceWorker'

Sentry.init({
  dsn: "https://957086ebcb4f4356a86fc96b6f643085@o431895.ingest.sentry.io/5383830",
  environment: process.env.REACT_APP_SENTRY_ENV,
  debug: false,
  integrations: [
    new Integrations.BrowserTracing()
  ],
  tracesSampleRate: 1.0,
  ignoreErrors: [/^Failed to fetch$/]
})

// toggle logging
if (process.env.REACT_APP_BUILD !== 'production') {
  localStorage.setItem('debug', `${process.env.REACT_APP_LOGGER_NAME}:*`)
}

ReactDOM.render(
    <App />, 
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister()