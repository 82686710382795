import _ from 'lodash'

const REST_API_URI = process.env.REACT_APP_REST_API_URI
const LOOKUP_API_URI = process.env.REACT_APP_LOOKUP_API_URI
const LOOKUP_API_BETA_URI = process.env.REACT_APP_LOOKUP_API_BETA_URI
const STATS_API_URI = process.env.REACT_APP_STATS_API_URI
const INVENTORY_API_URI = process.env.REACT_APP_INVENTORY_API_URI
const LOGS_API_URI = process.env.REACT_APP_LOGS_API_URI
const WAREHOUSE_API_URI = process.env.REACT_APP_WAREHOUSE_API_URI
const CHECKINS_API_URI = process.env.REACT_APP_CHECKINS_API_URI
const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN
const AUTH0_RETURN = `${window.location.origin}`
const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_KEY

const getAccessTokenHeaders = () => {
  const token = window.localStorage.getItem('token')
  const authUserId = window.localStorage.getItem('authUserId')

  if (token && authUserId) {
    try {
      return {
        'Authorization': `Bearer ${token}`,
        'X-User-ID': authUserId
      }
    } catch(err) {
      console.error(err)
    }
  }
  return {}
}

const http = async (endpoint, options, isAuth) => {
  if (isAuth) {
    options.headers =_.extend(options.headers, getAccessTokenHeaders())
    if (!options.headers['Authorization']) {
      localStorage.removeItem('token')
      localStorage.removeItem('authUserId')
      window.location = `https://${AUTH0_DOMAIN}/v2/logout?returnTo=${AUTH0_RETURN}&client_id=${AUTH0_CLIENT_ID}`
    }
  }

  const response = await fetch(endpoint, options)
  if (response) {
    const data = await response.json()
    if (response.ok && data) {
      return data
    } else {
      let forceLogout = false
      if (data && data.error && data.error.name && data.error.name === "UnauthorizedError") {
        forceLogout = true
      }
      throw new Error(JSON.stringify({ status: response.status, error: data, logout: forceLogout }))
    }
  }
}

const get = (endpoint, options = {}, isAuth = false) => {
  return http(REST_API_URI + endpoint, options, isAuth)
}

const getStats = (endpoint, options = {}, isAuth = false) => {
  return http(STATS_API_URI + endpoint, options, isAuth)
}

const getLookup = (endpoint, options = {}, isAuth = false) => {
  return http(LOOKUP_API_URI + endpoint, options, isAuth)
}

const getLogs = (endpoint, options = {}, isAuth = false) => {
  return http(LOGS_API_URI + endpoint, options, isAuth)
}

const getCheckins = (endpoint, options = {}, isAuth = false) => {
  return http(CHECKINS_API_URI + endpoint, options, isAuth)
}

const postLookup = (endpoint, payload, headers = {}, isAuth = false) => {
  const options = {
    method: 'POST',
    headers,
    body: JSON.stringify(payload)
  }

  let lookupUri = LOOKUP_API_URI
  if (payload.is_beta) {
    lookupUri = LOOKUP_API_BETA_URI
  }

  return http(lookupUri + endpoint, options, isAuth)
}

const postCheckins = (endpoint, payload, headers = {}, isAuth = false) => {
  const options = {
    method: 'POST',
    headers,
    body: JSON.stringify(payload)
  }

  return http(CHECKINS_API_URI + endpoint, options, isAuth)
}

const post = (endpoint, payload, headers = {}, isAuth = false) => {
  const options = {
    method: 'POST',
    headers,
    body: JSON.stringify(payload)
  }

  return http(REST_API_URI + endpoint, options, isAuth)
}

const put = (endpoint, payload, headers = {}, isAuth = false) => {
  const options = {
    method: 'PUT',
    headers,
    body: JSON.stringify(payload)
  }

  return http(REST_API_URI + endpoint, options, isAuth)
}

const remove = (endpoint, payload, headers = {}, isAuth = false) => {
  const options = {
    method: 'DELETE',
    headers,
    body: JSON.stringify(payload)
  }

  return http(REST_API_URI + endpoint, options, isAuth)
}

// Inventory

const getInventory = (endpoint, options = {}, isAuth = false) => {
  return http(INVENTORY_API_URI + endpoint, options, isAuth)
}

const postInventory = (endpoint, payload, headers = {}, isAuth = false) => {
  const options = {
    method: 'POST',
    headers,
    body: JSON.stringify(payload)
  }

  return http(INVENTORY_API_URI + endpoint, options, isAuth)
}

const putInventory = (endpoint, payload, headers = {}, isAuth = false) => {
  const options = {
    method: 'PUT',
    headers,
    body: JSON.stringify(payload)
  }

  return http(INVENTORY_API_URI + endpoint, options, isAuth)
}

const removeInventory = (endpoint, payload, headers = {}, isAuth = false) => {
  const options = {
    method: 'DELETE',
    headers,
    body: JSON.stringify(payload)
  }

  return http(INVENTORY_API_URI + endpoint, options, isAuth)
}

// Warehouse

const getWarehouse = (endpoint, options = {}, isAuth = false) => {
  return http(WAREHOUSE_API_URI + endpoint, options, isAuth)
}

const postWarehouse = (endpoint, payload, headers = {}, isAuth = false) => {
  const options = {
    method: 'POST',
    headers,
    body: JSON.stringify(payload)
  }

  return http(WAREHOUSE_API_URI + endpoint, options, isAuth)
}

const putWarehouse = (endpoint, payload, headers = {}, isAuth = false) => {
  const options = {
    method: 'PUT',
    headers,
    body: JSON.stringify(payload)
  }

  return http(WAREHOUSE_API_URI + endpoint, options, isAuth)
}

const removeWarehouse = (endpoint, payload, headers = {}, isAuth = false) => {
  const options = {
    method: 'DELETE',
    headers,
    body: JSON.stringify(payload)
  }

  return http(WAREHOUSE_API_URI + endpoint, options, isAuth)
}

const arrestee = {
  fetchReceipt: token => get(`/receipt/${token}`, {}, false),
  fetchToken: params => post(`/arrestee/token`, params, {}, false),
  postPayment: params => post(`/arrestee/payment`, params, {}, false),
  fetchArrestee: (token) => get(`/arrestee/${token}`, {}, false),
  searchAccounts: params => post(`/arrestee/search`, params, {}, false),
  recoverToken: id => post(`/arrestee/${id}/recover`, {}, {}, false),
  searchBondsmen: token => get(`/arrestee/${token}/bondsmen`, {}, false),
  updateSecondIndemnitor: (token, params) => put(`/arrestee/${token}/second_indemnitor`, params, {}, false),
  searchBondsmenAgents: (bondsmanId, countyId) => get(`/arrestee/bondsman/${bondsmanId}/agents?county=${countyId}`, {}, false),
  selectBondsman: (token, params) => put(`/arrestee/${token}/bondsman`, params, {}, false),
  fetchPayments: token => get(`/arrestee/${token}/payments`, {}, false),
  createPaymentCustomer: (token, params) => post(`/arrestee/${token}/customer`, params, {}, false),
  savePaymentInfo: (token, params) => post(`/arrestee/${token}/payment`, params, {}, false),
  fetchUrlsEmail: params => post(`/arrestee/forgot`, params, {}, false),
  createNotifyCustomer: params => post(`/arrestee/notify`, params, {}, false),
  fetchDefendantTimeline: (token, type) => get(`/arrestee/${token}/timeline/${type}`, {}, false),
  saveTimelineNote: (token, type, params) => post(`/arrestee/${token}/timeline/${type}`, params, {}, false),
  confirmQuote: token => put(`/arrestee/${token}/confirm_quote`, {}, {}, false),
  confirmPaymentPlan: token => put(`/arrestee/${token}/confirm_payment_plan`, {}, {}, false),
  fetchQuestionnaire: token => get(`/arrestee/${token}/questionnaire`, {}, false),
  saveQuestionnaireAnswer: (token, params) => post(`/arrestee/${token}/questionnaire`, params, {}, false),
  markQuestionaireFinished: token => post(`/arrestee/${token}/questionnaire/complete`, {}, {}, false),
  requestAlternateUpload: token => post(`/arrestee/${token}/upload/alternate`, {}, {}, false),
  fetchCheckin: token => get(`/arrestee/${token}/checkin`, {}, false),
  fetchCheckinFile: (token, key) => post(`/arrestee/${token}/checkin/file`, { key }, {}, false),
  agreeToTerms: (token, type) => put(`/arrestee/${token}/terms`, { type }, {}, false),
  saveProfileInformation: (token, data) => put(`/arrestee/${token}/info`, data, {}, false),
  updateAppCode: token => put(`/arrestee/${token}/code`, {}, {}, false)
}

const auth = {
  login: params => post(`/auth/login`, params),
  register: params => post(`/auth/register`, params),
  forgot: params => post(`/auth/request-password-reset`, params),
  reset: params => post(`/auth/reset-password`, params),
  change: params => post(`/auth/change-password`, params, {}, true)
}

const bondsman = {
  auth: ({ token, invite }) => post(`/bondsman/auth`, { invite }, { 'Authorization': 'Bearer ' + token }, false),
  fetchSignedUrl: () => post(`/bondsman/file/url`, {}, {}, true),
  updateEmployeeSettings: params => put(`/bondsman/employee/settings`, params, {}, true),
  saveCard: params => post(`/bondsman/account/card`, params, {}, true),
  saveUploadedFiles: params => post(`/bondsman/file`, params, {}, true),
  createNewCase: params => post(`/bondsman/case`, params, {}, true),
  fetchBondsmanByName: (slug, isEmployee) => get(`/bondsman/${slug}?isEmployee=${isEmployee}`, {}, false),
  fetchBondsman: extended => get(`/bondsman${extended ? '?extended=true' : ''}`, {}, true),
  search: ({ keyword, limit = 5 }) => get(`/bondsman/customer/search?keyword=${keyword}&limit=${limit}`, {}, true),
  fetchCustomers: ({ limit, page, filterStr }) => get(`/bondsman/customers?limit=${limit}&page=${page}${filterStr ? '&' + filterStr : ''}`, {}, true),
  fetchDeletedCustomers: () => get(`/bondsman/customers/deleted`, {}, true),
  fetchCustomersSnapshot: (type = 'STANDARD') => get(`/bondsman/customers/snapshot?type=${type}`, {}, true),
  fetchCustomer: id => get(`/bondsman/customer/${id}`, {}, true),
  fetchFiles: () => get(`/bondsman/files`, {}, true),
  fetchEmployees: (types = '') => get(`/bondsman/employees?type=${types}`, {}, true),
  updateFile: (id, params) => put(`/bondsman/file/${id}`, params, {}, true),
  syncTemplateData: id => put(`/bondsman/file/${id}/sync`, {}, {}, true),
  fetchBondsmanCounties: () => get(`/bondsman/counties`, {}, true),
  saveBondsmanCounty: params => post(`/bondsman/county`, params, {}, true),
  removeBondsmanCounty: id => remove(`/bondsman/county/${id}`, {}, {}, true),
  updateProfile: params => put(`/bondsman`, params, {}, true),
  updateProfileSeo: params => put(`/bondsman/seo`, params, {}, true),
  updateNotificationSettings: params => put(`/bondsman/settings/notifications`, params, {}, true),
  updateProfileSettings: params => put(`/bondsman/settings`, params, {}, true),
  verifyAccountSetup: params => put(`/bondsman/verify`, params, {}, true),
  fetchBondsmanProcessors: () => get(`/bondsman/processors`, {}, true),

  updateCourthouseInfo: (id, params) => put(`/bondsman/customer/${id}/courthouse`, params, {}, true),
  deleteCaseCourtDate: (id, courtDateId) => remove(`/bondsman/customer/${id}/courthouse/${courtDateId}`, {}, {}, true),

  updateCheckinInfo: (id, params) => put(`/bondsman/customer/${id}/checkin`, params, {}, true),
  deleteCheckinInfo: id => remove(`/bondsman/customer/${id}/checkin`, {}, {}, true),
  updateCheckinAddresses: (id, params) => put(`/bondsman/customer/${id}/checkin/addresses`, params, {}, true),

  sendInOfficeLink: (id, params) => post(`/bondsman/customer/${id}/office/request`, params, {}, true),
  migrateContentToWarehouse: token => put(`/bondsman/customer/${token}/migrate`, {}, {}, true),
  toggleManagerStatus: id => put(`/bondsman/customer/${id}/manager_status`, {}, {}, true),
  moveIndemnitor: (id, params) => put(`/bondsman/customer/${id}/move_indemnitor`, params, {}, true),
  updateBookingInfo: (id, params) => put(`/bondsman/customer/${id}/booking`, params, {}, true),
  setDefendantAssignee: (id, params) => put(`/bondsman/customer/${id}/employee`, params, {}, true),
  processFinances: id => put(`/bondsman/customer/${id}/process`, {}, {}, true),
  acceptCashPayment: (id, params) => put(`/bondsman/customer/${id}/cashout`, params, {}, true),
  deleteCashPayment: (id, params) => remove(`/bondsman/customer/${id}/payment`, params, {}, true),
  updateCaseOffice: (id, params) => put(`/bondsman/customer/${id}/office`, params, {}, true),
  updateForfeitureStatus: (id, params) => put(`/bondsman/customer/${id}/forfeiture/status`, params, {}, true),
  updateForfeiture: (id, params) => put(`/bondsman/customer/${id}/forfeiture`, params, {}, true),
  autoSaveInfo: (id, params) => put(`/bondsman/customer/${id}/autosave`, params, {}, true),
  requestApproval: id => put(`/bondsman/customer/${id}/approval`, {}, {}, true),
  adjustAndNotify: (id, params) => put(`/bondsman/customer/${id}/adjustment`, params, {}, true),
  adjustFinances: (id, params) => put(`/bondsman/customer/${id}/finances`, params, {}, true),
  adjustMetaData: (id, params) => put(`/bondsman/customer/${id}/meta`, params, {}, true),
  initializeCase: id => put(`/bondsman/customer/${id}/initialize`, {}, {}, true),
  updateInfoPacket: (id, params) => put(`/bondsman/customer/${id}/packet`, params, {}, true),
  resetInformation: (id, token) => put(`/bondsman/customer/${id}/packet/reset`, { token }, {}, true),
  updateDocuments: (id, params) => put(`/bondsman/customer/${id}/packet/documents`, params, {}, true),
  adjustCollateral: (id, packetId, params) => put(`/bondsman/customer/${id}/packet/${packetId}/collateral`, params, {}, true),
  toggleAsDefendant: (id, packetId) => put(`/bondsman/customer/${id}/packet/${packetId}/defendant`, {}, {}, true),
  savePacketInfo: (id, params) => put(`/bondsman/customer/${id}/packet/info`, params, {}, true),
  updateStatus: (id, params) => put(`/bondsman/customer/${id}/status`, params, {}, true),
  setCaseStatus: (id, params) => put(`/bondsman/customer/${id}/case_status`, params, {}, true),
  addAdditionalIndemnitor: (id) => post(`/bondsman/customer/${id}/indemnitor`, {}, {}, true),
  deleteIndemnitor: (id) => remove(`/bondsman/customer/${id}/indemnitor`, {}, {}, true),
  resendUniqueUrl: (id) => post(`/bondsman/customer/${id}/resend`, {}, {}, true),
  resetDocument: (id, params) => put(`/bondsman/customer/${id}/document/reset`, params, {}, true),
  getCombinedDocuments: (id, params = {}) => post(`/bondsman/customer/${id}/documents`, params, {}, true),
  getSecureUploadsUrl: id => get(`/bondsman/customer/${id}/uploads`, {}, true),
  getSecureFileUrls: id => get(`/bondsman/customer/${id}/upload/files`, {}, true),
  clientDecision: (id, params) => put(`/bondsman/customer/${id}/decision`, params, {}, true),
  archiveCase: id => put(`/bondsman/customer/${id}/archive`, {}, {}, true),
  deleteCase: id => remove(`/bondsman/customer/${id}`, {}, {}, true),
  reinstateCase: id => put(`/bondsman/customer/${id}/reinstate`, {}, {}, true),
  requestPayment: id => post(`/bondsman/customer/${id}/request_payment`, {}, {}, true),
  messageReceivedTimeline: (id, type) => put(`/bondsman/customer/${id}/timeline/${type.toLowerCase()}/received`, {}, {}, true),
  fetchTimeline: (id, type) => get(`/bondsman/customer/${id}/timeline/${type.toLowerCase()}`, {}, true),
  saveTimelineNote: (id, type, params) => post(`/bondsman/customer/${id}/timeline/${type.toLowerCase()}`, params, {}, true),
  deleteTimelineNote: (id, type, params) => remove(`/bondsman/customer/${id}/timeline/${type.toLowerCase()}`, params, {}, true),
  fetchCustomerQuestionnaire: id => get(`/bondsman/customer/${id}/questionnaire`, {}, true),
  resetQuestionnaire: id => put(`/bondsman/customer/${id}/questionnaire/reset`, {}, {}, true),
  bypassUploads: (id, type) => put(`/bondsman/customer/${id}/bypass_uploads`, { type }, {}, true),
  resetUploads: (id, type) => put(`/bondsman/customer/${id}/uploads/reset`, { type }, {}, true),
  issueRefund: id => put(`/bondsman/customer/${id}/refund`, {}, {}, true),
  updateCustomFields: (id, params) => put(`/bondsman/customer/${id}/custom_fields`, params, {}, true),
  saveBondsmanCountyRate: (id, params) => put(`/bondsman/county/${id}/rate`, params, {}, true),
  updateBondsmanLink: params => put(`/bondsman/link`, params, {}, true),
  sendInvite: params => post(`/bondsman/invite`, params, {}, true),
  deleteInvite: id => remove(`/bondsman/invite/${id}`, {}, {}, true),
  fetchUsers: () => get(`/bondsman/users`, {}, true),
  updateEmployee: (id, data) => put(`/bondsman/user/${id}`, data, {}, true),
  deleteUser: id => remove(`/bondsman/user/${id}`, {}, {}, true),
  fetchApplicationInfo: () => get(`/bondsman/application`, {}, true),
  saveApplicationInfo: params => post(`/bondsman/application`, params, {}, true),
  submitCompletedApplication: () => put(`/bondsman/application/complete`, {}, {}, true),
  quickAddUser: params => post(`/bondsman/application/add`, params, {}, true),
  fetchQuestions: () => get(`/bondsman/questionair`, {}, true),
  addQuestion: params => post(`/bondsman/questionair`, params, {}, true),
  upateQuestion: (questionId, params) => put(`/bondsman/questionair/${questionId}`, params, {}, true),
  removeQuestion: questionId => remove(`/bondsman/questionair/${questionId}`, {}, {}, true),
  saveQuestionairOrder: params => put(`/bondsman/questionair/order`, params, {}, true),
  markCaseClosed: id => put(`/bondsman/customer/${id}/close`, {}, {}, true),
  fetchCheckinFile: (id, key) => post(`/bondsman/customer/${id}/checkin/file`, { key }, {}, true),
  generateInvoice: id => post(`/bondsman/customer/${id}/invoice`, {}, {}, true),
  fetchCaseCheckinDocInfo: (agencyId, caseId) => getCheckins(`/info/${agencyId}/case/${caseId}`, {}, false)
}

const checkins = {
  snapshot: (agencyId, date) => getCheckins(`/checkins/${agencyId}/snapshot?dates=${date}`, {}, true),
  all: (agencyId, queryStr) => getCheckins(`/checkins/${agencyId}/all${queryStr}`, {}, true),
  fetch: (agencyId, checkinId) => getCheckins(`/checkins/${agencyId}/checkin/${checkinId}`, {}, false),
  logLocation: (caseId, params) => postCheckins(`/checkin/${caseId}/location`, params, {}, false),

  create: (params) => post(`/checkin`, params, {}, true),
  sendCheckinAlert: id => post(`/checkin/${id}/alert`, {}, {}, true),
  sendMissedCheckinAlerts: date => post(`/checkin/alerts?date=${date}`, {}, {}, true),
  saveCheckinSettings: data => put(`/checkin/settings`, data, {}, true),

  sendNotification: (checkinId, data) => post(`/checkin/${checkinId}/notification`, data, {}, true), // LIVE
  saveAppSettings: (checkinId, data) => put(`/checkin/${checkinId}/app/settings`, data, {}, true) // LIVE
}

const counties = {
  fetchCounties: state => get(`/counties?state=${state}`, {}, false),
  searchCounties: search => get(`/counties/search?search=${search}`, {}, false)
}

const courthouses = {
  all: (agencyId, queryStr) => getCheckins(`/courthouses/${agencyId}/all${queryStr}`, {}, true),
  fetchCaseCourtdates: (agencyId, caseId) => getCheckins(`/courthouses/${agencyId}/courthouse/${caseId}`, {}, true),
  deleteCaseCourtDate: (agencyId, caseId) => getCheckins(`/courthouses/${agencyId}/courthouse/${caseId}`, {}, true)
}

const defendant = {
  fetch: token => get(`/defendant/${token}`, {}, false),
  confirmTerms: token => put(`/defendant/${token}/terms`, {}, {}, false),
  getDefendantDocumentStatus: token => get(`/defendant/${token}/documents/status`, {}, false),
  fetchDefendantBondSeeker: token => get(`/defendant/${token}/seeker`, {}, false)
}

const documents = {
  fetchAllUserDocumentStatus: token => get(`/document/all`, { headers: { 'X-User-Token': token } }, false),
  fetchUserDocuments: token => get(`/document`, { headers: { 'X-User-Token': token } }, false),
  fetchDefendantDocuments: token => get(`/document/defendant`, { headers: { 'X-Defendant-Token': token } }, false),
  updateUserDocuments: (token, params) => put(`/document`, params, { 'X-User-Token': token }, false),
  updateDefendantDocuments: (token, params) => put(`/document/defendant`, params, { 'X-Defendant-Token': token }, false),
  fetchDocumentsUrl: (token, params, formBuilder = false) => {
    let url = `/document/url`
    if (formBuilder) url = `/document/url?manual=1`
    return post(url, params, { 'X-User-Token': token }, false)
  },
  // fetchDocumentsUrlDefendant: (token, params) => post(`/document/url/defendant`, params, { 'X-User-Token': token }, false),
  updateDocumentSubmission: (token, params, siToken = null) => {
    const headers = { 'X-User-Token': token }
    if (siToken) headers['X-User-SI-Token'] = siToken
    return put(`/document/submission`, params, headers, false)
  },
  updateDocumentSubmissionDefendant: (token, params) => put(`/document/submission/defendant`, params, { 'X-Defendant-Token': token }, false),
  fetchSampleData: isDefendant => get(`/document/sample/data${(isDefendant) ? '?defendant=true' : ''}`, {}, false), // In Form Builder
  fetchDeveloperData: () => get(`/document/developer/data`, {}, false) // In Process
}

const explorer = {
  fetch: id => get(`/explorer/${id}`, {}, true),
  fetchPrintables: id => get(`/explorer/${id}/printables`, {}, true),
  updateMetaData: (id, params) => put(`/explorer/${id}/file/meta`, params, {}, true),
  deleteFile: (id, fileId, key) => remove(`/explorer/${id}/file/${fileId}`, { key }, {}, true),
  sendRequest: (id, params) => post(`/explorer/${id}/request`, params, {}, true),
  generatePrintableForm: (id, params) => post(`/explorer/${id}/printable`, params, {}, true),
  savePostBailDocument: (id, requestId, params) => post(`/explorer/${id}/request/${requestId}/pdf`, params, {}, true),
  fetchRequest: token => get(`/explorer/request`, { headers: { 'X-Request-Token': token } }, false),
  getUrlFormRequest: id => get(`/explorer/request/${id}/form`, {}, false),
  markRequestComplete: id => put(`/explorer/request/${id}/complete`, {}, {}, false),
  deleteRequest: (id, requestId) => remove(`/explorer/${id}/request/${requestId}`, {}, {}, true)
}

const files = {
  fetchTokenizedUrl: (token, text) => post(`/files/url?text=${text ? 1 : 0}`, {}, { 'X-User-Token': token }, false),
  fetchSignedUrl: (token, params = {}) => post(`/files/upload`, params, { 'X-Upload-Token': token }, false),
  fetchSignedUrlPost: (token, params = {}) => post(`/files/upload?status=store`, params, { 'X-Upload-Token': token }, false),
  fetchUploads: token => get(`/files`, { headers: { 'X-Upload-Token': token } }, false),
  fetchSecureUrls: (token, params) => post(`/files/upload/urls`, params, { 'X-Upload-Token': token }, false),
  updateDocumentsUploaded: (token, params) => put(`/files/upload`, params, { 'X-Upload-Token': token }, false)
}

const forfeitures = {
  all: (query = null) => get(`/forfeitures${query ? query : ''}`, {}, true),
  fetchLiability: (query = null) => get(`/forfeitures/liability${query ? query : ''}`, {}, true)
}

const importer = {
  fetchImportStats: () => get(`/import/stats`, {}, true),
  importData: params => post(`/import`, params, {}, true)
}

const finance = {
  fetchSnapshot: () => get(`/finance/snapshot`, {}, true),
  fetchSettlements: (from, to) => get(`/finance/settlements?from=${from}&to=${to}`, {}, true),
  fetchTransactions: date => get(`/finance/transactions?date=${date}`, {}, true),
  fetchPayments: date => {
    if (date) {
      return get(`/finance/payments?date=${date}`, {}, true)
    }
    return get(`/finance/payments`, {}, true)
  },
  fetchRefunds: () => get(`/finance/refunds`, {}, true),
  fetchInvoices: () => get(`/finance/invoices`, {}, true),
  fetchInvoice: id => get(`/finance/invoice/${id}`, {}, true),
  findUserTransactions: searchText => post(`/finance/refund/search`, { searchText }, {}, true),
  issueRefund: params => post(`/finance/refund/issue`, params, {}, true),
  fetchSettlementById: id => get(`/finance/settlement/${id}`, {}, true),
  attachPaymentToCase: params => post(`/finance/payment/associate`, params, {}, true)
}

const inventory = {
  fetchAgency: agencyId => getInventory(`/agency/${agencyId}`, {}, true),
  createAgency: (agencyId, params) => postInventory(`/agency/${agencyId}`, params, {}, true),
  fetchSuretys: () => getInventory(`/surety/all`, {}, true),
  fetchEmployeePowers: employeeId => getInventory(`/employee/${employeeId}/powers`, {}, true),
  syncEmployees: (agencyId, params) => postInventory(`/agency/${agencyId}/employees/sync`, params, {}, true),
  fetchEmployees: agencyId => getInventory(`/agency/${agencyId}/employees`, {}, true),
  fetchSnapshot: agencyId => getInventory(`/agency/${agencyId}/snapshot`, {}, true),
  fetchPackages: agencyId => getInventory(`/agency/${agencyId}/packages`, {}, true),
  fetchPackageById: (agencyId, packageId) => getInventory(`/agency/${agencyId}/package/${packageId}`, {}, true),
  fetchPackagePowers: (agencyId, packageId) => getInventory(`/agency/${agencyId}/package/${packageId}/powers`, {}, true),
  createPackage: (agencyId, params) => postInventory(`/agency/${agencyId}/package`, params, {}, true),
  fetchDistribution: agencyId => getInventory(`/agency/${agencyId}/powers/distribute`, {}, true),
  assignDistribution: (agencyId, params) => putInventory(`/agency/${agencyId}/powers/assign`, params, {}, true),
  updateInventory: (agencyId, params) => putInventory(`/agency/${agencyId}/powers`, params, {}, true),
  savePowers: (agencyId, params) => postInventory(`/agency/${agencyId}/powers`, params, {}, true),
  fetchCasePowers: (agencyId, caseId) => getInventory(`/agency/${agencyId}/powers/${caseId}`, {}, true),
  deletePower: (agencyId, powerId) => removeInventory(`/agency/${agencyId}/power/${powerId}`, {}, {}, true)
  // fetchEmployeePowers: agencyId => getInventory(`/employee/${agencyId}/powers`, {}, true)
}

const link = {
  fetch: linkId => get(`/link/${linkId}`, {}, false)
}

const invoice = {
  deleteInvoice: invoiceId => remove(`/invoice/${invoiceId}`, {}, {}, true),
  bypassPayments: (invoiceId, params) => put(`/invoice/${invoiceId}/bypass`, params, {}, true),
  processPayments: invoiceId => put(`/invoice/${invoiceId}/process`, {}, {}, true)
}

const logs = {
  fetch: caseId => getLogs(`/logs/${caseId}`, {}, true),
  fetchLocationLogs: (caseId, date) => getLogs(`/logs/${caseId}/location?dateFrom=${date.from}&dateTo=${date.to}`, {}, true),
  fetchLastLocationInfo: caseId => getLogs(`/logs/${caseId}/location/last`, {}, true)
}

const lookup = {
  searchCounties: state => getLookup(`/counties?state=${state}`, {}, false),
  victimSearch: params => postLookup(`/search`, params, {}, false),
  victimSearchDetail: params => postLookup(`/search/detail`, params, {}, false)
}

const onboarding = {
  signup: params => post(`/fupa/bondsman`, params, {}, false),
  fetchBondsman: token => {
    const isLoggedIn = window.localStorage.getItem('token')
    if (!isLoggedIn) {
      return get(`/onboarding/bondsman/${token}/login`, {}, false)
    }
    return get(`/onboarding/bondsman/${token}`, {}, true)
  },
  saveOnboardingInfo: (token, data) => post(`/onboarding/bondsman/${token}`, data, {}, true),
  fetchApplication: applicationId => get(`/onboarding/application/${applicationId}`, {}, true),
  saveOnboardingInfoAdditional: (token, data) => post(`/onboarding/bondsman/${token}/additional`, data, {}, true),
  generateApplication: token => post(`/onboarding/bondsman/${token}/application`, {}, {}, true),
  updateOnboardingPassword: (token, params) => put(`/onboarding/bondsman/${token}/password`, params, {}, true)
}

const offices = {
  fetchOffices: () => get(`/offices`, {}, true),
  saveOffice: params => post(`/office`, params, {}, true),
  updateOffice: (id, params) => put(`/office/${id}`, params, {}, true),
  removeOffice: id => remove(`/office/${id}`, {}, {}, true),
  saveOfficeFile: (id, params) => post(`/office/${id}/file`, params, {}, true),
  saveOfficeLocation: (id, params) => post(`/office/${id}/location`, params, {}, true),
  updateOfficeSetting: (id, params) => put(`/office/${id}/setting`, params, {}, true),
  removeOfficeAttribute: (id, params) => remove(`/office/${id}/attribute`, params, {}, true)
}

const payment_plans = {
  fetchPaymentsSnapshot: () => get(`/payments/snapshot`, {}, true),
  fetchPaymentsByType: (type) => get(`/payments/type?type=${type}`, {}, true),
  fetchPayments: () => get(`/payments`, {}, true),
  fetchPaymentPlans: (queryStr = '') => get(`/payments/plans${queryStr}`, {}, true),
  fetchPlanById: planId => get(`/payments/plan/${planId}`, {}, true),
  fetchPaymentPlansByType: type => get(`/bondsman/payment_plans/${type}`, {}, true),
  fetchPaymentPlan: id => get(`/bondsman/customer/${id}/payment_plan`, {}, true),
  adjustPaymentPlan: (id, params) => put(`/bondsman/customer/${id}/payment_plan`, params, {}, true),
  deletePaymentPlan: id => remove(`/bondsman/customer/${id}/payment_plan`, {}, {}, true),
  cancelPaymentPlan: id => put(`/bondsman/plan/${id}/payment_plan/cancel`, {}, {}, true),
  markPaymentPlanPayoff: id => put(`/bondsman/customer/${id}/payment_plan/payoff`, {}, {}, true),
  // changeCardRequest: id => put(`/bondsman/customer/${id}/payment_plan/update_card`, {}, {}, true),
  updateCard: (id, params) => put(`/bondsman/customer/${id}/payment_plan/update_card`, params, {}, true),
  processPlanPayment: (planId, paymentId) => put(`/bondsman/plan/${planId}/payment/${paymentId}/process`, {}, {}, true),
  markPaymentReceived: (planId, paymentId, amount) => put(`/bondsman/plan/${planId}/payment/${paymentId}/received`, { amount }, {}, true),
  markPaymentReversed: (planId, paymentId) => put(`/payments/plan/${planId}/payment/${paymentId}/reversed`, {}, {}, true),
  editPayment: (planId, paymentId, data) => put(`/payments/plan/${planId}/payment/${paymentId}`, data, {}, true),
  addAdditionalPayments: (planId, data) => post(`/payments/plan/${planId}/payments`, data, {}, true),
  spreadToCurrentPayments: (planId, data) => put(`/payments/plan/${planId}/payments`, data, {}, true),
  deletePayment: (planId, paymentId) => remove(`/payments/plan/${planId}/payment/${paymentId}`, {}, {}, true)
}

const powers = {
  fetchCasePowers: caseId => get(`/case/${caseId}/powers`, {}, true),
  saveCasePower: (caseId, params) => post(`/case/${caseId}/power`, params, {}, true),
  updatePower: (caseId, powerId, params) => put(`/case/${caseId}/power/${powerId}`, params, {}, true),
  savePowerFinances: (caseId, powerId, params) => put(`/case/${caseId}/power/${powerId}/finances`, params, {}, true),
  removeCasePower: (caseId, powerId) => remove(`/case/${caseId}/power/${powerId}`, {}, {}, true)
}

const questionnaire = {
  fetch: (type, section) => get(`/bondsman/questions/${type}/${section}`, {}, true),
  save: (type, section, data) => post(`/bondsman/questions/${type}/${section}`, data, {}, true)
}

const reporting = {
  fetch: () => get(`/reporting`, {}, true),
  create: params => post(`/reporting/feature`, params, {}, true),
  update: (id, params) => put(`/reporting/feature/${id}`, params, {}, true),
  remove: id => remove(`/reporting/feature/${id}`, {}, {}, true),
  build: (id, params, type = 'VIEW') => post(`/reporting/report/${id}?type=${type}`, params, {}, true)
}

const rules = {
  fetchRuleTypes: () => get(`/rules/key_types`, {}, true),
  fetchRules: () => get(`/rules`, {}, true),
  createRule: data => post(`/rule`, data, {}, true),
  updateRule: (ruleId, data) => put(`/rule/${ruleId}`, data, {}, true),
  deleteRule: ruleId => remove(`/rule/${ruleId}`, {}, {}, true),
  fetchCaseRules: caseId => get(`/rules/case/${caseId}`, {}, true)
}

const settings = {
  fetch: () => get(`/settings`, {}, true),
  update: data => put(`/settings`, data, {}, true),
  fetchLocationSettings: id => get(`/settings/location/${id}`, {}, true),
  updateLocationSettings: (id, params) => put(`/settings/location/${id}`, params, {}, true)
}

const stats = {
  fetch: (bondsmanId, filterType) => getStats(`/stats/${bondsmanId}?filterType=${filterType}&site=${process.env.REACT_APP_BUILD}`, {}, false)
}

const surety = {
  all: () => get(`/sureties`, {}, true),
  saveAgencySuretySettings: data => put(`/sureties`, data, {}, true)
}

const user = {
  register: params => post(`/user/register`, params, {}, false),
  reset: params => post(`/user/reset`, params, {}, true),
  testText: params => post(`/user/text/test`, params, {}, true)
}

const warehouse = {
  buildAnswerGroupToken: token => postWarehouse(`/token/${token}`, {}, {}, false),
  fetchAnswerGroup: (token, jwt) => getWarehouse(`/token/${token}`, { headers: { 'X-Answer-Token': jwt } }),
  fetchAnswerGroupRequests: (token, jwt) => getWarehouse(`/token/${token}/requests`, { headers: { 'X-Answer-Token': jwt } }),
  fetchNextQuestion: (token, jwt) => getWarehouse(`/token/${token}/question`, { headers: { 'X-Answer-Token': jwt } }),
  answerQuestion: (token, questionId, params, jwt) => postWarehouse(`/token/${token}/question/${questionId}`, params, { 'X-Answer-Token': jwt }),
  bypassQuestions: (token, jwt) => postWarehouse(`/token/${token}/bypass`, {}, { 'X-Answer-Token': jwt }),
  fetchDocumentRequest: (token, requestId, jwt) => postWarehouse(`/token/${token}/request/${requestId}`, {}, { 'X-Answer-Token': jwt }),
  markRequestComplete: (token, params, jwt) => putWarehouse(`/token/${token}/request/complete`, params, { 'X-Answer-Token': jwt }),
  fetchAgencyAnswerGroup: token => getWarehouse(`/agency/token/${token}`, {}, true),
  fetchAgencyAnswerGroupRequests: token => getWarehouse(`/agency/token/${token}/requests`, {}, true),
  fetchAgencyAnswerGroupAnswers: token => getWarehouse(`/agency/token/${token}/answers`, {}, true),
  updateAgencyAnswerGroupAnswers: (token, params) => putWarehouse(`/agency/token/${token}/answers`, params, {}, true),
  updateAnswerGroup: (token, params) => putWarehouse(`/agency/token/${token}`, params, {}, true),
  fetchAgency: (withVault = false) => getWarehouse(`/agency?vault=${withVault ? true : false}`, {}, true),
  upsertAgency: () => put(`/bondsman/warehouse/agency`, {}, {}, true),
  updateAgencySettings: (id, params) => putWarehouse(`/agency/${id}/settings`, params, {}, true),
  deleteAllRows: id => removeWarehouse(`/agency/${id}/settings/security/rows`, {}, {}, true),
  syncWarehouse: id => putWarehouse(`/agency/${id}/sync`, {}, {}, true),
  syncEmployees: (id, params) => putWarehouse(`/agency/${id}/settings/employees`, params, {}, true),
  fetchSections: (area) => getWarehouse(`/agency/sections?area=${area}`, {}, true),
  fetchSectionsAndQuestions: (id, caseId) => getWarehouse(`/agency/${id}/group/${caseId}`, {}, false),
  fetchSectionQuestions: (sectionId) => getWarehouse(`/agency/section/${sectionId}/questions`, {}, true),
  resetSections: () => postWarehouse(`/agency/sections/reset`, {}, {}, true),
  createSection: (id, params) => postWarehouse(`/agency/${id}/section`, params, {}, true),
  updateSection: (id, params) => putWarehouse(`/agency/section/${id}`, params, {}, true),
  removeSection: (id) => removeWarehouse(`/agency/section/${id}`, {}, {}, true),
  updateSectionsOrder: (params) => putWarehouse(`/agency/sections/order`, params, {}, true),
  createQuestionAndAssign: (agencyId, sectionId, params) => postWarehouse(`/agency/${agencyId}/section/${sectionId}/question`, params, {}, true),
  updateQuestion: (agencyId, sectionId, questionId, data) => putWarehouse(`/agency/${agencyId}/section/${sectionId}/question/${questionId}`, data, {}, true),
  removeQuestion: (agencyId, sectionId, questionId) => removeWarehouse(`/agency/${agencyId}/section/${sectionId}/question/${questionId}/remove`, {}, {}, true),
  deleteQuestion: (agencyId, sectionId, questionId) => removeWarehouse(`/agency/${agencyId}/section/${sectionId}/question/${questionId}/delete`, {}, {}, true),
  updateQuestionsOrder: (agencyId, sectionId, params) => putWarehouse(`/agency/${agencyId}/section/${sectionId}/questions/order`, params, {}, true),
  createQuestionGroup: (id, params) => postWarehouse(`/agency/${id}/question/group`, params, {}, true),
  fetchToken: () => getWarehouse(`/vault/token`, {}, true),
  fetchCaseTokens: caseId => getWarehouse(`/vault/case/${caseId}`, {}, true),
  updateRecord: (caseId, params) => postWarehouse(`/vault/case/${caseId}`, params, {}, true),
  setupVault: agencyId => postWarehouse(`/vault/agency/${agencyId}`, {}, {}, true)
}

const vars = {
  arrestee,
  auth,
  bondsman,
  checkins,
  counties,
  courthouses,
  defendant,
  documents,
  explorer,
  files,
  finance,
  forfeitures,
  importer,
  inventory,
  invoice,
  link,
  logs,
  lookup,
  offices,
  onboarding,
  payment_plans,
  powers,
  questionnaire,
  reporting,
  rules,
  settings,
  stats,
  surety,
  user,
  warehouse
}

export default vars
