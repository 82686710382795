// import { toJS } from 'mobx'

export const getSettings = (settings, county) => {
  return { ...settings, ...county }
}

export const isActiveFeature = (bondsman, feature) => {
  const featureList = bondsman.features || []
  return (featureList.indexOf(feature) >= 0)
}

export const isSettingValid = (settings, key) => {
  return (settings && settings[key])
}

export const getMinMax = (settings, key, type, defaultSetting) => {
  if (isSettingValid(settings, key)) {
    if (!settings[key].active) return defaultSetting
    if (type === 'MIN') return settings[key].min
    if (type === 'MAX') return settings[key].max
  }
  return defaultSetting
}

export const getFeeKeyActive = (settings, key, defaultSetting) => {
  if (isSettingValid(settings, key)) {
    return settings[key].active
  }
  return defaultSetting
}

export const getFeeKeyAddToBond = (settings, key, defaultSetting) => {
  if (isSettingValid(settings, key)) {
    if (!settings[key].active) return defaultSetting
    return settings[key].addToBondFee
  }
  return defaultSetting
}

export const getFeeKeyText = (settings, key, defaultSetting) => {
  if (isSettingValid(settings, key)) {
    if (!settings[key].active) return defaultSetting
    return settings[key].text
  }
  return defaultSetting
}

export const getFeeKeyAmount = (settings, key, defaultSetting) => {
  if (isSettingValid(settings, key)) {
    if (!settings[key].active) return defaultSetting
    return settings[key].setAmount
  }
  return defaultSetting
}

export const getFeesTotal = fees => {
  let totalDue = 0
  if (fees) {
    fees.forEach(itm => {
      if (!itm.isAddedToBond && !itm.bypassTotal && !itm.cashCollateral) {
        if (itm.isRebate) {
          totalDue = (totalDue - itm.amount)
        } else {
          totalDue = (totalDue + itm.amount)
        }
      }
    })
  }
  return totalDue
}

export const getBondAmountCalculation = (bond, fees, bondRate, getRateTotal = false) => {
  let totalDue = bond
  
  if (fees) {
    fees.forEach(itm => {
      if (itm.isAddedToBond) {
        if (itm.isRebate) {
          totalDue = (totalDue - itm.amount)
        } else {
          totalDue = (totalDue + itm.amount)
        }
      }
    })
  }

  if (getRateTotal) return totalDue
  return (totalDue * (bondRate / 100))
}

export const addFeeIfValid = (settings, total, key, value) => {
  if (!getFeeKeyAddToBond(settings, key)) { // If you dont add it to the bond then this is ok
    if (settings[key].isRebate) {
      return (total - value)
    }
    return (total + value)
  }
  return total
}

export const getPriceWithCreditCardFees = (totalDue, customFields, apr = "3.5") => {
  if (!customFields || !customFields.eatCreditCardFees) {
    totalDue = (totalDue * (1 + (Number(apr)/100)))
  }
  return totalDue
}

export const getTotalWithFiances = (breakdown, apr, eatCreditCardFees, calculateSurcharge = true) => {
  const _getAgentAmount = breakdown => {
    if (breakdown) {
      let { bondAmount, agentRate, fees } = breakdown
      return getBondAmountCalculation(bondAmount, fees, agentRate)
    }
    return 0
  }
  const _getAmountDue = breakdown => {
    let totalDue = 0
    if (breakdown) {
      const { collateralFeesAmount, fees } = breakdown
      const feesAmount = getFeesTotal(fees)
      totalDue = _getAgentAmount(breakdown)
      totalDue += feesAmount
      totalDue += parseFloat(collateralFeesAmount)
    }
    return totalDue
  }
  const _getSurchargeAmount = (breakdown, apr) => {
    let totalDue = _getAmountDue(breakdown)
    return (!!eatCreditCardFees) ? 0 : ((totalDue * (1 + (Number(apr)/100))) - totalDue)
  }
  const amountDue = _getAmountDue(breakdown)
  let surchargeAmount = breakdown.surchargeAmount
  if (calculateSurcharge) surchargeAmount = _getSurchargeAmount(breakdown, apr, eatCreditCardFees)
  return (amountDue + surchargeAmount)
}