import React from 'react'
import { Provider } from 'mobx-react'
import { Router } from 'react-router'
import { createBrowserHistory } from 'history'
import stores from 'stores'
import Routes from 'routes'
import events from 'lib/analytics'
import { Auth0Provider } from "@auth0/auth0-react"

import 'styles/App.scss'

class Core extends React.Component {
  constructor (props) {
    super(props)

    this._history = createBrowserHistory()
    
    if (process.env.REACT_APP_GA) {
      events.init()
      events.pageView()
      this._history.listen((location) => events.pageView(location))
    }
  }

  _getClasses() {
    const userToken = localStorage.getItem('userToken')
    if (userToken) {
      return 'main--user'
    }
    return null
  }

  render () {

    let redirect = `${window.location.origin}/callback`

    return (
        <Provider {...stores}>
          <Auth0Provider
            domain={process.env.REACT_APP_AUTH0_DOMAIN}
            clientId={process.env.REACT_APP_AUTH0_KEY}
            redirectUri={redirect}
          >
          <Router history={this._history}>
            <React.Fragment>
              {this.props.children}
              <Routes />
            </React.Fragment>
          </Router>
        </Auth0Provider>
      </Provider>
    )
  }
}

export default Core
