import { observable, action, makeObservable } from "mobx"

import moment from 'moment'

// import pusher from 'lib/pusher'
import api from 'lib/api'
import { uploads } from 'lib/utils'
// import { areas, allSections } from 'lib/info-utils'

const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN
const AUTH0_RETURN = `${window.location.origin}`
const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_KEY

class BondsmanStore {
  // Observable
  uploads = null
  activeCounties = null
  bondsman = null
  allBondsmen = null
  customers = null
  pageInfo = null
  currentCustomer = null
  payment_plan = null
  sectionsAndQuestions = null
  files = null
  rules = null
  users = null
  invites = null
  timeline = null
  powers = null
  activity = []
  updateAvailable = null

  // Not Observable
  customerSearchOptions = { limit: 25, page: 1 }
  // isPusherInit = false

  constructor() {
    makeObservable(this, {
      updateAvailable: observable,
      activity: observable,
      uploads: observable,
      activeCounties: observable,
      rules: observable, 
      files: observable, 
      bondsman: observable,
      allBondsmen: observable,
      customers: observable,
      pageInfo: observable,
      currentCustomer: observable,
      users: observable,
      invites: observable,
      timeline: observable,
      questionair: observable,
      payment_plan: observable,
      powers: observable,
      sendInvite: action.bound,
      deleteUser: action.bound,
      fetchCustomers: action.bound,
      fetchBondsmanByName: action.bound,
      saveCard: action.bound,
      clientDecision: action.bound,
      adjustAndNotify: action.bound,
      adjustFinances: action.bound,
      fetchPaymentPlan: action.bound,
      processFinances: action.bound,
      autoSaveInfo: action.bound,
      updateProfile: action.bound,
      acceptCashPayment: action.bound,
      adjustPaymentPlan: action.bound,
      cancelPaymentPlan: action.bound,
      deletePaymentPlan: action.bound,
      payoffPaymentPlan: action.bound,
      changeCardRequest: action.bound,
      updateCaseOffice: action.bound,
      updateDocuments: action.bound,
      updateSettings: action.bound,
      adjustMetaData: action.bound,
      updateBookingInfo: action.bound,
      updateInfoPacket: action.bound,
      adjustCollateral: action.bound,
      updateStatus: action.bound,
      updateForfeitureStatus: action.bound,
      updateForfeiture: action.bound,
      toggleManagerStatus: action.bound,
      addAdditionalIndemnitor: action.bound,
      generateNewInvoice: action.bound,
      resetUploads: action.bound,
      markCaseClosed: action.bound,
      initializeCase: action.bound,
      updateCourthouseInfo: action.bound,
      takeTheCase: action.bound,
      setCaseStatus: action.bound,
      saveDefendantInfo: action.bound,
      register: action.bound,
      setCustomer: action.bound,
      fetchBondsmanProfile: action.bound,
      fetchUploadedFiles: action.bound,
      messageReceivedTimeline: action.bound,
      issueRefund: action.bound,
      setDefendantStatus: action.bound,
      fetchCounties: action.bound,
      updateUploadSetting: action.bound,
      saveBondsmanSettings: action.bound,
      fetchUsers: action.bound,
      updateEmployee: action.bound,
      setDefendantAssignee: action.bound,
      fetchTimeline: action.bound,
      submitCompletedApplication: action.bound,
      fetchCustomerQuestionnaire: action.bound,
      resetQuestionnaire: action.bound,
      addQuestion: action.bound,
      setQuestionairOrder: action.bound,
      removeQuestion: action.bound,
      bypassUploads: action.bound,
      setCustomField: action.bound,
      setAgencyCustomField: action.bound,
      fetchCaseRules: action.bound,
      unlockDocs: action.bound,
      fetchCasePowers: action.bound,
      saveCasePower: action.bound,
      savePowerFinances: action.bound,
      updatePower: action.bound,
      removeCasePower: action.bound,
      savePacketInfo: action.bound
    })
  }

  // Events

  // https://auth0.com/docs/api/authentication?javascript#logout
  doLogout() {
    localStorage.removeItem('token')
    localStorage.removeItem('authUserId')
    let logoutEndpoint = `https://${AUTH0_DOMAIN}/v2/logout?returnTo=${AUTH0_RETURN}&client_id=${AUTH0_CLIENT_ID}`
    window.location = logoutEndpoint
  }

  async fetchSignedUrl() {
    try {
      return api.bondsman.fetchSignedUrl()
    } catch(e) {
      console.error(e)
    }
  }

  async fetchUsers() {
    try {
      const results = await api.bondsman.fetchUsers()
      if (results) {
        this.users = results.employees
        this.invites = results.invites
        return this.users
      }
      return null
    } catch(e) {
      console.error(e)
    }
  }

  async updateEmployee(userId, data) {
    try {
      this.users = await api.bondsman.updateEmployee(userId, data)
      return this.users
    } catch(e) {
      console.error(e)
    }
  }

  async deleteUser(id) {
    try {
      const results = await api.bondsman.deleteUser(id)
      if (results) {
        return this.fetchUsers()
      }
    } catch(e) {
      console.error(e)
    }
  }

  async sendInvite(params) {
    const results = await api.bondsman.sendInvite(params)
    if (results) {
      await this.fetchUsers()
      return results
    }
  }

  async fetchSectionsAndQuestions(caseId = null) {
    caseId = caseId || this.currentCustomer?.id
    if (caseId) {
      const { sections } = await api.warehouse.fetchSectionsAndQuestions(this.bondsman.id, caseId)
      if (sections) {
        this.sectionsAndQuestions = sections
        return this.sectionsAndQuestions
      }
    }
    return []
  }

  // async toggleAdmin(id) {
  //   try {
  //     const results = await api.bondsman.toggleAdmin(id)
  //     if (results) {
  //       return this.fetchUsers()
  //     }
  //   } catch(e) {
  //     console.error(e)
  //   }
  // }

  // async deleteInvite(id) {
  //   try {
  //     const results = await api.bondsman.deleteInvite(id)
  //     if (results) {
  //       return this.fetchUsers()
  //     }
  //   } catch(e) {
  //     console.error(e)
  //   }
  // }

  // async resendInvite(id) {
  //   try {
  //     const results = await api.bondsman.resendInvite(id)
  //     if (results) {
  //       return results
  //     }
  //   } catch(e) {
  //     console.error(e)
  //   }
  // }

  async fetchUploadedFiles() {
    try {
      const { files, suretys } = await api.bondsman.fetchFiles()

      this.files = files

      this.files = this.files.map(file => {
        file.surety = null
        if (file.type === 'SURETY') {
          const surety = suretys.find(itm => itm.forms.indexOf(file.id) >= 0)
          file.surety = { id: surety.id, name: surety.name }
        }
        const isFound = this.bondsman.settings.activeDocuments.find(itm => itm.id === file.id)
        if (isFound) {
          file.isMandatory = isFound.isMandatory
          file.isMandatoryDefendant = isFound.isMandatoryDefendant
          file.isActive = isFound.isActive
        } else {
          file.isMandatory = false
          file.isMandatoryDefendant = false
          file.isActive = false
        }
        return file
      })
      .filter(itm => itm.isActive)

      return this.files
    } catch(e) {
      console.error(e)
    }
  }

  async saveUploadedFiles(files) {
    try {
      this.files = await api.bondsman.saveUploadedFiles({ files })
      return this.files
    } catch(e) {
      console.error(e)
    }
  }

  async submitCompletedApplication() {
    try {
      const results = await api.bondsman.submitCompletedApplication()
      if (results) {
        this.bondsman.status = 'PENDING'
        return this.bondsman
      }
      throw new Error('Could not compelte application.')
    } catch(e) {
      console.error(e)
    }
  }

  async updateFile(file) {
    try {
      this.files = await api.bondsman.updateFile(file.id, { file })
      return this.files
    } catch(e) {
      console.error(e)
    }
  }

  async searchBondsmen(token) {
    try {
      this.allBondsmen = await api.arrestee.searchBondsmen(token)
      return this.allBondsmen
    } catch(e) {
      console.error(e)
    }
  }

  async searchBondsmenAgents(token, bondsmanId) {
    return api.arrestee.searchBondsmenAgents(token, bondsmanId)
  }

  async fetchBondsmanByName(slug, isEmployee = false) {
    return api.bondsman.fetchBondsmanByName(slug, isEmployee)
  }

  async fetchBondsmanProfile(extended = false, force = true) {
    try {
      if (!force && this.bondsman) return this.bondsman
      this.payment_plan = null
      const results = await api.bondsman.fetchBondsman(extended)
      if (results) {

        if (
          results.theBriggsLockout && 
          window.location.pathname.indexOf('/agent/dashboard') < 0 && 
          window.location.pathname.indexOf('/welcome/') < 0 && 
          window.location.pathname !== '/agent/activation'
        ) {
          window.location = '/agent/activation'
        }

        this.bondsman = results
        this.bondsman.isTrial = (moment().diff(moment(results.trialEndDate), 'days') < 0)
        this.uploads = uploads.map(upload => {
          const doesExist = this.bondsman.settings.activeUploads.find(itm => itm.id === upload.id)
          if (doesExist) {
            upload.isActive = doesExist.isActive
            upload.isFrontBack = doesExist.isFrontBack
            upload.isMandatory = doesExist.isMandatory
            upload.isActiveDefendant = doesExist.isActiveDefendant
            upload.isMandatoryDefendant = doesExist.isMandatoryDefendant
            upload.isMandatoryAdditional = doesExist.isMandatoryAdditional
          } else {
            upload.isActive = false
            upload.isFrontBack = false
            upload.isMandatory = false
            upload.isActiveDefendant = false
            upload.isMandatoryDefendant = false
            upload.isMandatoryAdditional = false
          }
          return upload
        })
        return this.bondsman
      }
    } catch(e) {
      const errorJson = JSON.parse(e.message)
      if (errorJson) {
        console.error(errorJson)
        // if (errorJson.logout) this.doLogout()
      } else {
        console.error(e)
      }
    }
  }

  fetchCustomersSnapshot(type = 'STANDARD') {
    return api.bondsman.fetchCustomersSnapshot(type)
  }

  async saveCard(params) {
    const results = await api.bondsman.saveCard(params)
    if (results) {
      this.bondsman = { ...this.bondsman, ...results }
      return this.bondsman
    } else {
      throw new Error('Problem saving the decision.')
    }
  }

  // Counties

  async fetchCounties(state) {
    try {
      this.counties = await api.counties.fetchCounties(state)
      return this.counties
    } catch(e) {
      console.error(e)
    }
  }

  async fetchBondsmanCounties() {
    try {
      const results = await api.bondsman.fetchBondsmanCounties()
      if (results) {
        this.activeCounties = results.filter(itm => itm.state !== 'OTHER')
        return this.activeCounties
      }
      return null
    } catch(e) {
      console.error(e)
    }
  }

  async getBondsmanCounties() {
    return api.bondsman.fetchBondsmanCounties()
  }

  // Profile and Accounts

  async verifyAccountSetup(code) {
    try {
      return api.bondsman.verifyAccountSetup({ code })
    } catch(e) {
      console.error(e)
    }
  }

  async updateProfile(input) {
    try {
      this.bondsman = await api.bondsman.updateProfile(input)
      return this.bondsman
    } catch(e) {
      console.error(e)
    }
  }

  async updateBondsmanLink(bondsmanLink) {
    try {
      const results = await api.bondsman.updateBondsmanLink({ bondsmanLink })
      if (results) {
        this.bondsman = { ...this.bondsman, bondsmanLink: results.bondsmanLink }
        return this.bondsman
      }
    } catch(e) {
      console.error(e)
    }
  }

  async fetchCustomers({ force = false, reset = false, filters = [] }) {
    try {
      
      let filterStr = null
      if (filters && filters.length) {
        filterStr = filters.map(itm => {
          if (itm.type === 'STATUS') {
            return `${itm.key}=${itm.value.join(',')}`
          } else if (itm.type === 'DATE') {
            return [`from=${itm.value.from}`,`to=${itm.value.to}`]
          }
          return `${itm.key}=${itm.value}`
        }).flat().join('&')
      }

      if (force || reset || !this.customers) {
        if (reset) {
          this.customers = []
          this.customerSearchOptions = { limit: 25, page: 1 }
        }

        let { limit, page } = this.customerSearchOptions
        this.customers = this.customers || []

        const results = await api.bondsman.fetchCustomers({ limit, page, filterStr })
        if (results) {
          this.pageInfo = { ...results.paging, hasMore: (page < results.paging.pages ) }
          const missingCustomers = results.customers.filter(itm => {
            return !(this.customers.find(c => c.id === itm.id))
          })
          if (missingCustomers) {
            this.customers = this.customers.concat(missingCustomers)
          }
          this.customerSearchOptions.page = this.pageInfo.nextPage
        }
      }
      return this.customers
    } catch(e) {
      console.error(e)
    }
  }

  async fetchTimeline(type) {
    try {
      if (this.currentCustomer) {
        this.timeline = await api.bondsman.fetchTimeline(this.currentCustomer.id, type)
        if (!this.timeline) {
          this.timeline = []
        }
        return this.timeline
      } else {
        throw new Error('Customer not selected.')
      }
    } catch(e) {
      console.error(e)
    }
  }

  async fetchCustomerQuestionnaire() {
    try {
      if (this.currentCustomer) {
        return api.bondsman.fetchCustomerQuestionnaire(this.currentCustomer.id)
      }
      throw new Error('Customer not selected.')
    } catch(e) {
      console.error(e)
    }
  }

  async resetQuestionnaire() {
    try {
      if (this.currentCustomer) {
        await api.bondsman.resetQuestionnaire(this.currentCustomer.id)
        return this.fetchCustomer(this.currentCustomer.id)
      }
      throw new Error('Customer not selected.')
    } catch(e) {
      console.error(e)
    }
  }

  async saveTimelineNote(type, params) {
    try {
      if (this.currentCustomer) {
        params.data = { noteTaker: { nameFirst: this.bondsman.employee.nameFirst, nameLast: this.bondsman.employee.nameLast } }
        this.timeline = await api.bondsman.saveTimelineNote(this.currentCustomer.id, type, params)
        if (!this.timeline) {
          this.timeline = []
        }
        return this.timeline
      } else {
        throw new Error('Customer not selected.')
      }
    } catch(e) {
      console.error(e)
    }
  }

  async deleteTimelineNote(type, { id }) {
    try {
      if (this.currentCustomer) {
        this.timeline = await api.bondsman.deleteTimelineNote(this.currentCustomer.id, type, { id })
        if (!this.timeline) {
          this.timeline = []
        }
        return this.timeline
      } else {
        throw new Error('Customer not selected.')
      }
    } catch(e) {
      console.error(e)
    }
  }

  async clientDecision(decision, params = null) {
    try {
      if (this.currentCustomer) {
        const newCustomerInfo = await api.bondsman.clientDecision(this.currentCustomer.id, { ...params, decision })
        if (newCustomerInfo) {
          // this.customers = this.customers.map(itm => {
          //   if (itm.id === newCustomerInfo.id) itm.assignee = newCustomerInfo.assignee
          //   return itm
          // })
          return this.fetchCustomers()
        } else {
          throw new Error('Problem saving the decision.')
        }
      } else {
        throw new Error('Customer not selected.')
      }
    } catch(e) {
      console.error(e)
    }
  }

  async archiveCase() {
    if (this.currentCustomer) {
      const results = await api.bondsman.archiveCase(this.currentCustomer.id)
      if (results) {
        return true
      }
      throw new Error('Problem Archiving the case.')
    }
    throw new Error('Customer not selected.')
  }

  async deleteCase() {
    if (this.currentCustomer) {
      const results = await api.bondsman.deleteCase(this.currentCustomer.id)
      if (results) {
        return true
      }
      throw new Error('Problem saving the decision.')
    }
    throw new Error('Customer not selected.')
  }

  async resetDocument(templateId) {
    try {
      if (this.currentCustomer) {
        const results = await api.bondsman.resetDocument(this.currentCustomer.id, { templateId })
        if (results) {
          this.currentCustomer = { ...this.currentCustomer, ...results }
          return this.fetchCustomers()
        } else {
          throw new Error('Problem saving the decision.')
        }
      } else {
        throw new Error('Customer not selected.')
      }
    } catch(e) {
      console.error(e)
    }
  }

  // Adjustments

  async generateNewInvoice() {
    const results = await api.bondsman.generateInvoice(this.currentCustomer.id)
    if (results) {
      return this.fetchCustomer(this.currentCustomer.id)
    } else {
      throw new Error('Problem saving the decision.')
    }
  }

  async initializeCase() {
    this.currentCustomer.isConfirmed = true
    const results = await api.bondsman.initializeCase(this.currentCustomer.id)
    if (results) {
      return this.fetchCustomer(this.currentCustomer.id)
    } else {
      throw new Error('Problem saving the decision.')
    }
  }

  async updateCourthouseInfo(params) {
    const results = await api.bondsman.updateCourthouseInfo(this.currentCustomer.id, params)
    if (results) {
      this.currentCustomer = { ...this.currentCustomer, ...results } // courthouseInfo
      return this.currentCustomer
    } else {
      throw new Error('Problem saving the decision.')
    }
  }

  async updateCaseOffice(params) {
    const results = await api.bondsman.updateCaseOffice(this.currentCustomer.id, params)
    if (results) {
      this.currentCustomer = { ...this.currentCustomer, ...results } // checkin
      return this.currentCustomer
    } else {
      throw new Error('Problem saving the decision.')
    }
  }

  async updateForfeitureStatus(params) {
    const results = await api.bondsman.updateForfeitureStatus(this.currentCustomer.id, params)
    if (results) {
      return this.fetchCustomer(this.currentCustomer.id)
    } else {
      throw new Error('Problem saving the decision.')
    }
  }

  async updateForfeiture(params) {
    const results = await api.bondsman.updateForfeiture(this.currentCustomer.id, params)
    if (results) {
      return this.fetchCustomer(this.currentCustomer.id)
    } else {
      throw new Error('Problem saving the decision.')
    }
  }

  async fetchPaymentPlan(customerId = null) {
    const results = await api.payment_plans.fetchPaymentPlan(customerId || this.currentCustomer.id)
    if (results) {
      this.payment_plan = results.payment_plan
      return this.payment_plan
    } else {
      throw new Error('Problem saving the decision.')
    }
  }

  async adjustPaymentPlan(params) {
    const results = await api.payment_plans.adjustPaymentPlan(this.currentCustomer.id, params)
    if (results) {
      this.payment_plan = results.plan
      this.currentCustomer.paymentPlanId = this.payment_plan.id
      await this.fetchCustomer(this.currentCustomer.id)
      return this.payment_plan
    } else {
      throw new Error('Problem saving the decision.')
    }
  }

  async deletePaymentPlan() {
    const results = await api.payment_plans.deletePaymentPlan(this.currentCustomer.id)
    if (results) {
      this.payment_plan = null
      this.currentCustomer.paymentPlanId = null
      await this.fetchCustomer(this.currentCustomer.id)
      return this.payment_plan
    } else {
      throw new Error('Problem saving the decision.')
    }
  }

  async cancelPaymentPlan(planId) {
    const results = await api.payment_plans.cancelPaymentPlan(planId)
    if (results) {
      return results.plan
    } else {
      throw new Error('Problem saving the decision.')
    }
  }

  async payoffPaymentPlan() {
    const results = await api.payment_plans.payoffPaymentPlan(this.currentCustomer.id)
    if (results) {
      return this.payment_plan
    } else {
      throw new Error('Problem saving the decision.')
    }
  }

  async changeCardRequest() {
    const results = await api.payment_plans.changeCardRequest(this.currentCustomer.id)
    if (results) {
      return this.payment_plan
    } else {
      throw new Error('Problem saving the decision.')
    }
  }

  async setAgencyCustomField(params) {
    const results = await api.bondsman.updateCustomFields(this.currentCustomer.id, params)
    if (results) {
      return this.fetchCustomer(this.currentCustomer.id)
    } else {
      throw new Error('Problem saving the decision.')
    }
  }

  async adjustFinances(params) {
    const results = await api.bondsman.adjustFinances(this.currentCustomer.id, params)
    if (results) {
      return this.fetchCustomer(this.currentCustomer.id)
    } else {
      throw new Error('Problem saving the decision.')
    }
  }

  async acceptCashPayment(data) {
    const results = await api.bondsman.acceptCashPayment(this.currentCustomer.id, data)
    if (results) {
      if (this.powers?.length) {
        await this.fetchCasePowers(this.currentCustomer.id)
      }
      return this.fetchCustomer(this.currentCustomer.id)
    } else {
      throw new Error('Problem saving the decision.')
    }
  }

  async processFinances() {
    const results = await api.bondsman.processFinances(this.currentCustomer.id)
    if (results) {
      return this.fetchCustomer(this.currentCustomer.id)
    } else {
      throw new Error('Problem saving the decision.')
    }
  }

  async autoSaveInfo(params) {
    const results = await api.bondsman.autoSaveInfo(this.currentCustomer.id, params)
    if (results) {
      if (results.charges || results.bookingNumber) {
        this.currentCustomer = { ...this.currentCustomer,  defendant: { ...this.currentCustomer.defendant, ...results } }
      } else {
        this.currentCustomer = { ...this.currentCustomer, ...results }
      }
      return this.currentCustomer
    } else {
      throw new Error('Problem saving the decision.')
    }
  }

  async adjustMetaData(params) {
    const results = await api.bondsman.adjustMetaData(this.currentCustomer.id, params)
    if (results) {
      return this.fetchCustomer(this.currentCustomer.id)
    } else {
      throw new Error('Problem saving the decision.')
    }
  }

  async updateBookingInfo(params) {
    const results = await api.bondsman.updateBookingInfo(this.currentCustomer.id, params)
    if (results) {
      return this.fetchCustomer(this.currentCustomer.id)
    } else {
      throw new Error('Problem saving the decision.')
    }
  }

  async updateInfoPacket(params) {
    const results = await api.bondsman.updateInfoPacket(this.currentCustomer.id, params)
    if (results) {
      return this.fetchCustomer(this.currentCustomer.id)
    }
    throw new Error('Problem saving the decision.')
  }

  async resetInformation(token) {
    const results = await api.bondsman.resetInformation(this.currentCustomer.id, token)
    if (results) {
      return this.fetchCustomer(this.currentCustomer.id)
    }
    throw new Error('Problem saving the decision.')
  }

  async updateDocuments(params) {
    const results = await api.bondsman.updateDocuments(this.currentCustomer.id, params)
    if (results) {
      return this.fetchCustomer(this.currentCustomer.id)
    }
    throw new Error('Problem saving the decision.')
  }

  async toggleAsDefendant(packetId) {
    const results = await api.bondsman.toggleAsDefendant(this.currentCustomer.id, packetId)
    if (results) {
      return this.fetchCustomer(this.currentCustomer.id)
    }
    throw new Error('Problem saving the decision.')
  }

  async adjustCollateral(packetId, params) {
    const results = await api.bondsman.adjustCollateral(this.currentCustomer.id, packetId, params)
    if (results) {
      return this.fetchCustomer(this.currentCustomer.id)
    } else {
      throw new Error('Problem saving the decision.')
    }
  }

  async updateStatus(params) {
    const results = await api.bondsman.updateStatus(this.currentCustomer.id, params)
    if (results) {
      return this.fetchCustomer(this.currentCustomer.id)
    } else {
      throw new Error('Problem saving the decision.')
    }
  }

  async addAdditionalIndemnitor(params) {
    const results = await api.bondsman.addAdditionalIndemnitor(this.currentCustomer.id, params)
    if (results) {
      return this.fetchCustomer(this.currentCustomer.id)
    } else {
      throw new Error('Problem saving the decision.')
    }
  }

  async resetUploads(type) {
    const results = await api.bondsman.resetUploads(this.currentCustomer.id, type)
    if (results) {
      return this.fetchCustomer(this.currentCustomer.id)
    } else {
      throw new Error('Problem saving the decision.')
    }
  }

  async deleteIndemnitor(id) {
    const results = await api.bondsman.deleteIndemnitor(id)
    if (results) {
      return this.fetchCustomer(this.currentCustomer.id)
    } else {
      throw new Error('Problem saving the decision.')
    }
  }

  async sendInOfficeLink(params) {
    const results = await api.bondsman.sendInOfficeLink(this.currentCustomer.id, params)
    if (results) {
      return this.fetchCustomer(this.currentCustomer.id)
    } else {
      throw new Error('Problem saving the decision.')
    }
  }

  async resendUniqueUrl(id) {
    const results = await api.bondsman.resendUniqueUrl(id)
    if (results) {
      return results
    } else {
      throw new Error('Problem saving the decision.')
    }
  }

  async requestPayment() {
    const results = await api.bondsman.requestPayment(this.currentCustomer.id)
    if (results) {
      return this.fetchCustomer(this.currentCustomer.id)
    } else {
      throw new Error('Problem saving the decision.')
    }
  }

  async requestApproval() {
    const results = await api.bondsman.requestApproval(this.currentCustomer.id)
    if (results) {
      return this.fetchCustomer(this.currentCustomer.id)
    } else {
      throw new Error('Problem saving the decision.')
    }
  }

  async setCaseStatus(status) {
    const results = await api.bondsman.setCaseStatus(this.currentCustomer.id, { status })
    if (results) {
      return this.fetchCustomer(this.currentCustomer.id)
    } else {
      throw new Error('Problem saving the decision.')
    }
  }

  async setDefendantAssignee(employee) {
    const results = await api.bondsman.setDefendantAssignee(this.currentCustomer.id, { employee })
    if (results) {
      return this.fetchCustomer(this.currentCustomer.id)
    } else {
      throw new Error('Problem saving the decision.')
    }
  }

  async toggleManagerStatus() {
    const results = await api.bondsman.toggleManagerStatus(this.currentCustomer.id, {})
    if (results) {
      // this.currentCustomer = { ...this.currentCustomer, ...results }
      return this.fetchCustomer(this.currentCustomer.id)
    }
    throw new Error('Problem toggling Manager Status.')
  }

  async savePacketInfo(type, data) {
    const results = await api.bondsman.savePacketInfo(this.currentCustomer.id, { type, data })
    if (results) {
      return this.fetchCustomer(this.currentCustomer.id)
    }
    throw new Error('Problem toggling Manager Status.')
  }

  async messageReceivedTimeline(type) {
    const results = await api.bondsman.messageReceivedTimeline(this.currentCustomer.id, type.toLowerCase())
    if (results) {
      const newDocs = this.currentCustomer.documents.map(itm => {
        if (itm.type === type.toUpperCase()) {
          itm.hasNotified = 0
        }
        return itm
      })
      this.currentCustomer.documents = newDocs
      return this.currentCustomer
    } else {
      throw new Error('Problem saving the decision.')
    }
  }

  async adjustAndNotify(params) {
    try {
      if (this.currentCustomer) {

        params = { ...params, customFields: this.currentCustomer.customFields }

        const results = await api.bondsman.adjustAndNotify(this.currentCustomer.id, params)
        if (results) {
          return this.fetchCustomers()
        } else {
          throw new Error('Problem saving the decision.')
        }
      } else {
        throw new Error('Customer not selected.')
      }
    } catch(e) {
      console.error(e)
    }
  }

  async markCaseClosed() {
    if (this.currentCustomer) {
      const results = await api.bondsman.markCaseClosed(this.currentCustomer.id)
      if (results) {
        this.currentCustomer.defendant.defendantStatus = results.defendantStatus
        return this.currentCustomer
      } else {
        throw new Error('Problem saving the decision.')
      }
    } else {
      throw new Error('Customer not selected.')
    }
  }

  async saveDefendantInfo(params) {
    if (this.currentCustomer) {
      const results = await api.bondsman.saveDefendantInfo(this.currentCustomer.id, params)
      if (results) {
        return this.fetchCustomers()
      } else {
        throw new Error('Problem saving the decision.')
      }
    } else {
      throw new Error('Customer not selected.')
    }
  }

  async moveIndemnitor(params) {
    try {
      if (this.currentCustomer) {
        await api.bondsman.moveIndemnitor(this.currentCustomer.id, params)
        return this.fetchCustomer(this.currentCustomer.id)
      } else {
        throw new Error('Customer not selected.')
      }
    } catch(e) {
      console.error(e)
    }
  }

  async fetchCustomer(customerId = null) {
    if (!customerId) customerId = this.currentCustomer.id
    const customer = await api.bondsman.fetchCustomer(customerId)
    if (customer) {
      customer.documents = customer.documents.map(doc => {
        if (!!doc.customFields?.documentsBypassed) {
          doc.isFormsComplete = true
          doc.isInformationComplete = true
        }
        if (!!doc.customFields?.uploadsBypassed) {
          doc.isUploadsComplete = true
        }
        return doc
      })
      const primaryIndemitor = customer.documents.find(itm => itm.type === 'INDEMNITOR')
      if (primaryIndemitor && (!primaryIndemitor.nameFirst || primaryIndemitor.nameLast)) {
        customer.documents = customer.documents.map(itm => {
          if (itm.type === 'INDEMNITOR') {
            itm.nameFirst = customer.nameFirst
            itm.nameLast = customer.nameLast
            itm.phone = customer.phone
            itm.email = customer.email
          }
          return itm
        })
      }
      this.currentCustomer = customer
      return this.currentCustomer
    }
    throw new Error('Could not fetch customer.')
  }

  async getCombinedDocuments(id) {
    return api.bondsman.getCombinedDocuments(id)
  }

  async getSecureUploadsUrl(id) {
    return api.bondsman.getSecureUploadsUrl(id)
  }

  async getSecureFileUrls(id) {
    return api.bondsman.getSecureFileUrls(id)
  }

  async saveBankAccountInfo(params) {
    try {
      this.bondsman = await api.bondsman.saveBankAccountInfo(params)
      return this.bondsman
    } catch(e) {
      console.error(e)
    }
  }

  async fetchApplicationInfo() {
    try {
      return api.bondsman.fetchApplicationInfo()
    } catch(e) {
      console.error(e)
    }
  }

  async saveApplicationInfo(applicationInfo) {
    try {
      return api.bondsman.saveApplicationInfo({ applicationInfo })
    } catch(e) {
      console.error(e)
    }
  }

  async quickAddUser(params) {
    return api.bondsman.quickAddUser(params)
  }

  async resetCustomer() {
    this.currentCustomer = null
    this.payment_plan = null
  }

  async bypassUploads(id, type) {
    const results = await api.bondsman.bypassUploads(id, type)
    if (results) {
      return this.fetchCustomer(this.currentCustomer.id)
    }
  }

  setCustomField(key, value) {
    this.currentCustomer.customFields = this.currentCustomer.customFields || {}
    this.currentCustomer.customFields[key] = value
  }

  // Settings

  async fetchSettings() {
    const settings = await api.settings.fetch()
    if (settings) {
      return settings
    }
    return null
  }

  async updateSettings(data) {
    const settings = await api.settings.update(data)
    if (settings) {
      await this.fetchBondsmanProfile()
      return settings
    }
    return null
  }

  async saveBondsmanSettings() {
    this.bondsman.settings.activeUploads = this.uploads.map(itm => {
      return {
        id: itm.id,
        isActive: itm.isActive,
        isFrontBack: itm.isFrontBack,
        isMandatory: itm.isMandatory,
        isMandatoryDefendant: itm.isMandatoryDefendant
      }
    })

    this.bondsman.settings.activeDocuments = this.files.map(itm => {
      return {
        id: itm.id,
        isMandatory: itm.isMandatory || false,
        isMandatoryDefendant: itm.isMandatoryDefendant || false
      }
    })

    const results = await api.bondsman.updateProfileSettings(this.bondsman.settings)
    if (results) {
      this.bondsman = { ...this.bondsman, settings: results.settings }
      return this.bondsman
    }
  }

  unlockDocs() {
    this.currentCustomer.isFormsComplete = false
  }

  unlockDefendantDocs() {
    this.currentCustomer.defendantProfile.isFormsComplete = false
  }

  // Questionair

  questionair = null

  async fetchQuestions() {
    const results = await api.bondsman.fetchQuestions()
    if (results) {
      this.questionair = results.sort((a, b) => a.order < b.order ? -1 : a.order > b.order ? 1 : 0)
      return this.questionair
    }
    return null
  }

  async addQuestion({ name, question, type }) {
    const params = { name, question, type, order: (this.questionair.length + 1) }
    const results = await api.bondsman.addQuestion(params)
    if (results) {
      this.questionair = results.sort((a, b) => a.order < b.order ? -1 : a.order > b.order ? 1 : 0)
      return this.questionair
    }
    return null
  }

  async setQuestionairOrder(questions) {
    const results = await api.bondsman.saveQuestionairOrder({ questions })
    if (results) {
      this.questionair = results.sort((a, b) => a.order < b.order ? -1 : a.order > b.order ? 1 : 0)
      return this.questionair
    }
    return null
  }

  async removeQuestion(questionId) {
    const results = await api.bondsman.removeQuestion(questionId)
    if (results) {
      this.questionair = results.sort((a, b) => a.order < b.order ? -1 : a.order > b.order ? 1 : 0)
      return this.questionair
    }
    return null
  }

  // Rules

  fetchRuleTypes() {
    return api.rules.fetchRuleTypes()
  }

  fetchRules() {
    return api.rules.fetchRules()
  }

  async fetchCaseRules(caseId) {
    const results = await api.rules.fetchCaseRules(caseId)
    if (results) {
      this.rules = results.rules
      return results
    }
    return results
  }

  createRule(data) {
    return api.rules.createRule(data)
  }

  updateRule(id, data) {
    return api.rules.updateRule(id, data)
  }

  deleteRule(ruleId) {
    return api.rules.deleteRule(ruleId)
  }

  // Powers

  async fetchCasePowers(caseId) {
    const results = await api.powers.fetchCasePowers(caseId)
    if (results) {
      this.powers = results.powers
      return this.powers
    }
    throw new Error('Error fetching Case Powers')
  }

  async saveCasePower(caseId, data) {
    const results = await api.powers.saveCasePower(caseId, data)
    if (results) {
      this.powers = results.powers
      return this.powers
    }
    throw new Error('Error save Case Power')
  }

  async savePowerFinances(caseId, powerId, finances) {
    const results = await api.powers.savePowerFinances(caseId, powerId, { finances })
    if (results) {
      await this.fetchCustomer(this.currentCustomer.id)
      this.powers = this.powers.map(itm => itm.id === powerId ? results.power : itm)
      return results.power
    }
    throw new Error('Error save Case Power')
  }

  async updatePower(caseId, powerId, params) {
    const results = await api.powers.updatePower(caseId, powerId, params)
    if (results) {
      this.powers = this.powers.map(itm => itm.id === powerId ? results.power : itm)
      return results.power
    }
    throw new Error('Error save Case Power')
  }

  async removeCasePower(powerId) {
    const results = await api.powers.removeCasePower(this.currentCustomer.id, powerId)
    if (results) {
      this.powers = this.powers.filter(itm => itm.id !== powerId)
      await this.fetchCustomer(this.currentCustomer.id)
      return results.power
    }
    throw new Error('Error save Case Power')
  }

  // Other

  toggleFileUpload(id, type) {
    this.uploads = this.uploads.map(itm => {
      if (itm.id === id) {
        if (type === 'activeState') {
          itm.isActive = (itm.isActive) ? false : true
        } else if (type === 'frontBack') {
          itm.isFrontBack = (itm.isFrontBack) ? false : true
        } else if (type === 'mandatory') {
          itm.isMandatory = (itm.isMandatory) ? false : true
        } else if (type === 'mandatoryDefendant') {
          itm.isMandatoryDefendant = (itm.isMandatoryDefendant) ? false : true
        }
      }
      return itm
    })
  }

  toggleFileSetting(id, type) {
    this.files = this.files.map(itm => {
      if (itm.id === id) {
        if (type === 'mandatory') {
          itm.isMandatory = (itm.isMandatory) ? false : true
        } else if (type === 'mandatoryDefendant') {
          itm.isMandatoryDefendant = (itm.isMandatoryDefendant) ? false : true
        }
      }
      return itm
    })
  }

  canViewCases(bondsman) {
    if (bondsman.settings.hideCases && ['USER','ASSISTANT'].indexOf(bondsman.employee.type) >= 0) {
      return false
    }
    return true
  }

  _pullData(results, name) {
    if (results && results.data && results.data[name]) {
      return results.data[name]
    }
    console.error('[Error] Results: ', results)
    throw new Error('Bad data.')
  }

  takeTheCase() {
    return
  }

  register() {
    return
  }

  setCustomer() {
    return
  }

  issueRefund() {
    return
  }

  updateUploadSetting() {
    return
  }

  setDefendantStatus() {
    return
  }

}

export default BondsmanStore
