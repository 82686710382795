import { observable, action, makeObservable } from "mobx"

import api from 'lib/api'

class FinancesStore {
  snapshot = null
  settlements = null
  releasedFunds = null
  payments = null
  refunds = null
  transactions = null
  invoices = null
  stats = null

  constructor() {
    makeObservable(this, {
      stats: observable,
      settlements: observable,
      releasedFunds: observable,
      payments: observable,
      refunds: observable,
      transactions: observable,
      invoices: observable,
      fetchSnapshot: action.bound,
      fetchSettlements: action.bound,
      fetchPayments: action.bound,
      fetchRefunds: action.bound,
      fetchTransactions: action.bound,
      fetchInvoices: action.bound
    })
  }

  async fetchSnapshot() {
    const results = await api.finance.fetchSnapshot()
    if (results) {
      this.snapshot = results
      return this.snapshot
    }
    return null
  }

  async fetchSettlements(filterDate = null) {
    let from = ''
    let to = ''
    if (filterDate) {
      from = filterDate.from
      to = filterDate.to
    }
    const results = await api.finance.fetchSettlements(from, to)
    if (results) {
      let currentSettlements = {}
      results.settlements.forEach(s => {
        if (!currentSettlements[s.eventId]) {
          currentSettlements[s.eventId] = s
          currentSettlements[s.eventId].cases = [s.caseInfo]
        } else {
          currentSettlements[s.eventId].cases.push(s.caseInfo)
        }
      })
      this.settlements = [...Object.values(currentSettlements)]
      this.releasedFunds = results.fundsReleased
      return this.settlements
    }
    return null
  }

  async fetchPayments(date = null) {
    const results = await api.finance.fetchPayments(date)
    if (results) {
      this.payments = results.payments
      return this.payments
    }
    return null
  }

  async fetchTransactions(date) {
    const results = await api.finance.fetchTransactions(date)
    if (results) {
      this.transactions = results.transactions.map(itm => {
        const event = results.events.find(e => e.eventId === itm.eventId)
        itm.cardInfo = (event) ? event.card : {}
        return itm
      })
      return this.transactions
    }
    return null
  }

  async fetchRefunds() {
    const results = await api.finance.fetchRefunds()
    if (results) {
      this.refunds = results.refunds
      return this.refunds
    }
    return null
  }

  async fetchInvoices() {
    const results = await api.finance.fetchInvoices()
    if (results) {
      this.invoices = results.invoices
      return this.invoices
    }
    return null
  }
}

export default FinancesStore
