import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { withAuthenticationRequired } from '@auth0/auth0-react'

import Loadable from 'react-loadable'
import Loading from 'components/Loading'

import Callback from 'layouts/Callback'

const Home = Loadable({
  loader: () => import('layouts/Home'),
  loading: Loading
})

const Activation = Loadable({
  loader: () => import('layouts/Activation'),
  loading: Loading
})

const Login = Loadable({
  loader: () => import('layouts/Login'),
  loading: Loading
})

const LearningCenter = Loadable({
  loader: () => import('layouts/LearningCenter'),
  loading: Loading
})

const Receipt = Loadable({
  loader: () => import('layouts/Receipt'),
  loading: Loading
})

const MagicLink = Loadable({
  loader: () => import('layouts/MagicLink'),
  loading: Loading
})

const MagicEmployee = Loadable({
  loader: () => import('layouts/MagicEmployee'),
  loading: Loading
})

const Magic = Loadable({
  loader: () => import('layouts/Magic'),
  loading: Loading
})

const MagicPayment = Loadable({
  loader: () => import('layouts/MagicPayment'),
  loading: Loading
})

const MagicCode = Loadable({
  loader: () => import('layouts/MagicCode'),
  loading: Loading
})

const SecureUpload = Loadable({
  loader: () => import('layouts/SecureUpload'),
  loading: Loading
})

const Lookup = Loadable({
  loader: () => import('layouts/Lookup'),
  loading: Loading
})

const Register = Loadable({
  loader: () => import('layouts/Register'),
  loading: Loading
})

const Example = Loadable({
  loader: () => import('layouts/Example'),
  loading: Loading
})

const NotFound = Loadable({
  loader: () => import('layouts/NotFound'),
  loading: Loading
})

const AgentRegister = Loadable({
  loader: () => import('layouts/AgentRegister'),
  loading: Loading
})

const AgentDashboard = Loadable({
  loader: () => import('layouts/AgentDashboard'),
  loading: Loading
})

const AgentSettings = Loadable({
  loader: () => import('layouts/AgentSettings'),
  loading: Loading
})

const AgentRules = Loadable({
  loader: () => import('layouts/AgentRules'),
  loading: Loading
})

const AgentFinances = Loadable({
  loader: () => import('layouts/AgentFinances'),
  loading: Loading
})

const AgentInventory = Loadable({
  loader: () => import('layouts/AgentInventory'),
  loading: Loading
})

const AgentOffices = Loadable({
  loader: () => import('layouts/AgentOffices'),
  loading: Loading
})

const AgentQualify = Loadable({
  loader: () => import('layouts/AgentQualify'),
  loading: Loading
})

const AgentReporting = Loadable({
  loader: () => import('layouts/AgentReporting'),
  loading: Loading
})

const AgentWarehouse = Loadable({
  loader: () => import('layouts/AgentWarehouse'),
  loading: Loading
})

const AgentForfeitures = Loadable({
  loader: () => import('layouts/AgentForfeitures'),
  loading: Loading
})

const Case = Loadable({
  loader: () => import('layouts/Case'),
  loading: Loading
})

const Defendant = Loadable({
  loader: () => import('layouts/Defendant'),
  loading: Loading
})

const DocumentUpload = Loadable({
  loader: () => import('layouts/DocumentUpload'),
  loading: Loading
})

const Documents = Loadable({
  loader: () => import('layouts/Documents'),
  loading: Loading
})

const Sign = Loadable({
  loader: () => import('layouts/Sign'),
  loading: Loading
})

const DefendantBondsman = Loadable({
  loader: () => import('layouts/DefendantBondsman'),
  loading: Loading
})

const PaymentInfo = Loadable({
  loader: () => import('layouts/PaymentInfo'),
  loading: Loading
})

const Quote = Loadable({
  loader: () => import('layouts/Quote'),
  loading: Loading
})

const PaymentPlan = Loadable({
  loader: () => import('layouts/PaymentPlan'),
  loading: Loading
})

const Questionnaire = Loadable({
  loader: () => import('layouts/Questionnaire'),
  loading: Loading
})

const PageAgentTerms = Loadable({
  loader: () => import('layouts/PageAgentTerms'),
  loading: Loading
})

const PageTerms = Loadable({
  loader: () => import('layouts/PageTerms'),
  loading: Loading
})

const PagePolicy = Loadable({
  loader: () => import('layouts/PagePolicy'),
  loading: Loading
})

const Releases = Loadable({
  loader: () => import('layouts/Releases'),
  loading: Loading
})

const Logout = Loadable({
  loader: () => import('layouts/Logout'),
  loading: Loading
})

const FormBuilder = Loadable({
  loader: () => import('layouts/FormBuilder'),
  loading: Loading
})

const Onboarding = Loadable({
  loader: () => import('layouts/Onboarding'),
  loading: Loading
})

const PaymentsDashboard = Loadable({
  loader: () => import('layouts/PaymentsDashboard'),
  loading: Loading
})

const CheckinDashboard = Loadable({
  loader: () => import('layouts/CheckinDashboard'),
  loading: Loading
})

let routes = [
  {
    path: '/',
    exact: true,
    component: Home,
    isPrivate: false
  },
  {
    path: '/logout',
    exact: true,
    component: Logout,
    isPrivate: false
  },
  {
    path: '/example',
    exact: true,
    component: Example,
    isPrivate: false
  },
  {
    path: '/agent/activation',
    exact: true,
    component: Activation,
    isPrivate: true
  },
  {
    path: [
      '/learning-center/:section/:page',
      '/learning-center/:section',
      '/learning-center'
    ],
    exact: true,
    component: LearningCenter,
    isPrivate: false
  },
  {
    path: '/welcome/:onboardingToken',
    exact: true,
    component: Onboarding,
    isPrivate: false
  },
  {
    path: '/login',
    exact: true,
    component: Login,
    isPrivate: false
  },
  {
    path: [
      '/r/:token'
    ],
    exact: true,
    component: Receipt,
    isPrivate: false
  },
  {
    path: [
      '/l/:slug'
    ],
    exact: true,
    component: MagicLink,
    isPrivate: false
  },
  {
    path: [
      '/e/:slug'
    ],
    exact: true,
    component: MagicEmployee,
    isPrivate: false
  },
  {
    path: [
      '/m/:slug/payment'
    ],
    exact: true,
    component: MagicPayment,
    isPrivate: false
  },
  {
    path: [
      '/m/:slug/office'
    ],
    exact: true,
    component: MagicCode,
    isPrivate: false
  },
  {
    path: [
      '/m/:slug/office/:officeSlug',
      '/m/:slug/:location/:county',
      '/m/:slug'
    ],
    exact: true,
    component: Magic,
    isPrivate: false
  },
  {
    path: '/lookup/:token',
    exact: true,
    component: Lookup,
    isPrivate: false
  },
  {
    path: '/upload/:token',
    exact: true,
    component: SecureUpload,
    isPrivate: false
  },
  {
    path: [
      '/register/:countySlug',
      '/register'
    ],
    exact: true,
    component: Register,
    isPrivate: false
  },
  {
    path: '/releases',
    exact: true,
    component: Releases,
    isPrivate: false
  },
  {
    path: '/u/:token',
    exact: true,
    component: Defendant,
    isPrivate: false
  },
  {
    path: '/d/:token',
    exact: true,
    component: DocumentUpload,
    isPrivate: false
  },
  {
    path: [
      '/u/:token/documents'
    ],
    exact: true,
    component: Documents,
    isPrivate: false
  },
  {
    path: [
      '/u/:token/sign'
    ],
    exact: true,
    component: Sign,
    isPrivate: false
  },
  {
    path: '/u/:token/bondsman',
    exact: true,
    component: DefendantBondsman,
    isPrivate: false
  },
  {
    path: '/u/:token/paymentInfo',
    exact: true,
    component: PaymentInfo,
    isPrivate: false
  },
  {
    path: '/u/:token/quote',
    exact: true,
    component: Quote,
    isPrivate: false
  },
  {
    path: '/u/:token/payments',
    exact: true,
    component: PaymentPlan,
    isPrivate: false
  },
  {
    path: '/u/:token/questionnaire',
    exact: true,
    component: Questionnaire,
    isPrivate: false
  },
  {
    path: [
      '/agent/checkins',
      '/agent/checkins/active',
      '/agent/checkins/courthouse',
      '/agent/checkins/settings',
      '/agent/checkins/user/:checkinId'
    ],
    exact: true,
    component: CheckinDashboard,
    isPrivate: true
  },
  {
    path: [
      '/agent/dashboard',
      '/agent/dashboard/leads',
      '/agent/dashboard/customers',
      '/agent/dashboard/customers/new',
      '/agent/dashboard/customers/ready'
    ],
    exact: true,
    component: AgentDashboard,
    isPrivate: true
  },
  {
    path: [
      '/agent/payments',
      '/agent/payments/plans',
      '/agent/payments/plan/:planId',
      '/agent/payments/:type'
    ],
    exact: true,
    component: PaymentsDashboard,
    isPrivate: true
  },
  {
    path: '/agent/register',
    exact: true,
    component: AgentRegister,
    isPrivate: true
  },
  {
    path: [
      '/agent/settings',
      '/agent/settings/profile',
      '/agent/settings/employees',
      '/agent/settings/notifications',
      '/agent/settings/reinstate',
      '/agent/settings/importer'
    ],
    exact: true,
    component: AgentSettings,
    isPrivate: true
  },
  {
    path: '/agent/rules',
    exact: true,
    component: AgentRules,
    isPrivate: true
  },
  {
    path: [
      '/agent/finances',
      '/agent/finances/invoices',
      '/agent/finances/settlements',
      '/agent/finances/payments',
      '/agent/finances/transactions',
      '/agent/finances/refunds',
      '/agent/finances/refunds/:caseId'
    ],
    exact: true,
    component: AgentFinances,
    isPrivate: true
  },
  {
    path: [
      '/agent/inventory',
      '/agent/inventory/sheets',
      '/agent/inventory/sheet/:sheetId',
      '/agent/inventory/employees',
      '/agent/inventory/settings'
    ],
    exact: true,
    component: AgentInventory,
    isPrivate: true
  },
  {
    path: [
      '/agent/offices',
      '/agent/offices/processors',
      '/agent/offices/locations',
      '/agent/offices/forms',
      '/agent/offices/uploads'
    ],
    exact: true,
    component: AgentOffices,
    isPrivate: true
  },
  {
    path: [
      '/agent/case/:customerId',
      '/agent/case/:customerId',
      '/agent/case/:customerId/info',
      '/agent/case/:customerId/notes',
      '/agent/case/:customerId/indemnitor/:indemnitor_slug',
      '/agent/case/:customerId/files',
      '/agent/case/:customerId/logs',
      '/agent/case/:customerId/files/:indemnitor_slug'
    ],
    exact: true,
    component: Case,
    isPrivate: true
  },
  {
    path: [
      '/agent/warehouse',
      '/agent/warehouse/sections',
      '/agent/warehouse/settings',
      '/agent/warehouse/section/:sectionId/questions',
      '/agent/warehouse/section/:sectionId/question/:questionId'
    ],
    exact: true,
    component: AgentWarehouse,
    isPrivate: true
  },
  {
    path: [
      '/agent/reporting',
      '/agent/reporting/templates'
    ],
    exact: true,
    component: AgentReporting,
    isPrivate: true
  },
  {
    path: [
      '/agent/forfeitures'
    ],
    exact: true,
    component: AgentForfeitures,
    isPrivate: true
  },
  {
    path: [
      '/agent/qualify'
    ],
    exact: true,
    component: AgentQualify,
    isPrivate: true
  },
  {
    path: '/terms',
    exact: true,
    component: PageTerms,
    isPrivate: false
  },
  {
    path: '/terms/agent',
    exact: true,
    component: PageAgentTerms,
    isPrivate: false
  },
  {
    path: '/privacy',
    exact: true,
    component: PagePolicy,
    isPrivate: false
  },
  {
    path: [
      '/form/:formId',
      '/form/:formId/d'
    ],
    exact: true,
    component: FormBuilder,
    isPrivate: false
  }
]

const RouteWithSubRoutes = (route) => (
  <Route path={route.path} render={props => (
    <route.component {...props} routes={route.routes} />
  )} />
)

const ProtectedRoute = ({ component, ...args }) => (
  <Route
    component={withAuthenticationRequired(component, {
      onRedirecting: () => {
        window.localStorage.setItem('redirectLocation', window.location.pathname)
        return <Loading text="Please Wait" />
      }
    })}
    {...args}
  />
)

const Routes = () => (
  <BrowserRouter>
    <Switch>
      {routes.map((route, i) => (
        route.isPrivate ? <ProtectedRoute key={i} {...route} /> : <RouteWithSubRoutes key={i} {...route} /> //  <PrivateRoute key={i} {...route} />
      ))}
      <Route path="/callback" render={(props) => {
        return <Callback {...props} />
      }}/>
      <Route component={NotFound} />
    </Switch>
  </BrowserRouter>
)

export default Routes
